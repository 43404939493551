<template>
    <div>
        <Header :id="id" :exhibitionId="exhibitionId"></Header>
        <div class="main">
            <div class="info">
                <div class="inof1">
                    <div class="left">
                        <div class="left1">{{ $t('about') }}</div>
                        <div class="left2">{{list.companyName}}</div>
                    </div>  
                    <div class="right">
                        <div class="right1">{{ $t('CountryRegion') }}</div>
                        <div class="right2">{{list.country}}</div>
                    </div>
                </div>
                <div class="inof1">
                    <div class="left">
                        <div class="left1">{{ $t('ContactName') }}</div>
                        <div class="left2">{{liaison.concat}}</div>
                    </div>  
                    <div class="right">
                        <div class="right1">{{ $t('ContactEmail') }}</div>
                        <div class="right2">{{liaison.email}}</div>
                    </div>
                </div>
                <div class="inof1">
                    <div class="left">
                        <div class="left1">{{ $t('ContactJobTitle') }}</div>
                        <div class="left2">{{liaison.position}}</div>
                    </div>  
                    <div class="right">
                        <div class="right1">{{ $t('ContactMob') }}</div>
                        <div class="right2">{{liaison.phone}}</div>
                    </div>
                </div>
                <div class="inof1">
                    <div class="left">
                        <div class="left1">{{ $t('ContactTel') }}</div>
                        <div class="left2">{{liaison.flPhone}}</div>
                    </div>  
                    <div class="right">
                        <div class="right1">{{ $t('ContactFax') }}</div>
                        <div class="right2">{{liaison.fax}}</div>
                    </div>
                </div>
                <div class="inof1">
                    <div class="left">
                        <div class="left1">{{ $t('address') }}</div>
                        <div class="left2">{{list.address}}</div>
                    </div>  
                    <div class="right">
                        <div class="right1">{{ $t('Website') }}</div>
                        <div class="right2"><a :href="list.website" target="_blank">{{list.website}}</a></div>
                    </div>
                </div>
            </div>
            <!-- <div class="info2">
                <div class="info3">
                    {{ $t('getcontact') }}
                </div>
            </div> -->
            <Contact></Contact>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Contact from "./components/Contact";
import Footer from "../components/Footer";

export default {
  name: "ContactUs",
  components: {
    Footer,
    Header,
    Contact
  },
  data () {
    return {
        exhibitionId: this.$route.params.exhibitionId,
        id: this.$route.params.id,
        list: {},
        liaison: {}
    }   
  },
  mounted () {
    this.getConmpany();
  },
   methods: {
     // 获取公司信息
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result
          this.liaison = result.liaison
          console.log('liar:',this.list)
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
   }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/spcontact.less';
</style>